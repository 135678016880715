import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import {
  BatchWriteCommand,
  DeleteCommand,
  DynamoDBDocumentClient,
  GetCommand,
  PutCommand,
  QueryCommand,
  ScanCommand,
  UpdateCommand,
} from '@aws-sdk/lib-dynamodb';
import { dynamoConfig } from './aws/configs';

export function generateLocalRequestContext() {
  return {
    authorizer: {
      'custom:org': 'demo',
      'custom:role': 'CIS_INTERNAL',
      'custom:email': 'sclements@ciswired.com',
    },
  };
}

export function getQueryParam(paramName, req) {
  const value = req?.query?.[paramName];
  if (!value) {
    return undefined;
  }
  return value;
}

export const createDdbClient = () => {
  const client = new DynamoDBClient(dynamoConfig);
  return DynamoDBDocumentClient.from(client);
};

const docClient = createDdbClient();

export const commandCall = async (command, params) => {
  const dDBCommands = {
    QueryCommand: QueryCommand,
    ScanCommand: ScanCommand,
    GetCommand: GetCommand,
    PutCommand: PutCommand,
    UpdateCommand: UpdateCommand,
    DeleteCommand: DeleteCommand,
    BatchWriteCommand: BatchWriteCommand,
  };
  const com = new dDBCommands[command](params);
  return await docClient.send(com);
};

import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React from 'react';

const GeneralUseDialog = ({
  open,
  setOpen,
  contentTitle,
  contentText,
  closeHandler = () => {},
}) => {
  const handleClose = () => {
    closeHandler();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
      <DialogTitle>
        {contentTitle}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          aria-label='close dialog'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default GeneralUseDialog;

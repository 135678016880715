import { ORG_TABLE_NAME } from '@/utils/aws/configs';
import { validateAndFixPermissionsConfig } from '@/utils/hubPermissions/hubPermissionsConfig';
import { getOrg } from '@/utils/hubPermissions/orgs';
import { commandCall } from '@/utils/request';

export async function getUser(email, orgName) {
  const { Item: user } = await commandCall('GetCommand', {
    TableName: ORG_TABLE_NAME,
    Key: {
      PK: `ORG:${orgName}`,
      SK: `USER:${email}`,
    },
  });

  if (!user) {
    return {};
  }

  // If the user is an admin or CIS_INTERNAL, they get all permissions of their Org.
  let basePermissions = undefined;
  if (['CIS_INTERNAL', 'ADMIN'].includes(user.role)) {
    const org = await getOrg(orgName);
    basePermissions = org.config;
  }

  const { config, changed } = validateAndFixPermissionsConfig(
    user.config,
    basePermissions
  );
  user.config = config;

  if (changed) {
    await updateUser(email, orgName, user);
  }

  return user || {};
}

export async function updateUser(email, orgName, user) {
  await commandCall('PutCommand', {
    TableName: ORG_TABLE_NAME,
    Item: {
      ...user,
      PK: `ORG:${orgName}`,
      SK: `USER:${email}`,
    },
  });
}

export function isElevated(user) {
  return user?.isInternal || user?.isAdmin;
}

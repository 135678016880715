import useSession from '@/utils/hooks/useSession';
import moment from 'moment-timezone';

// Get the current time as either a string or moment.js object.
export function getCurrentTime(timezone = 'GMT', raw = false) {
  const time = moment().tz(timezone);
  return raw ? time : time.format();
}

export function getTimezones(config) {
  let names = moment.tz.names();
  if (config?.codes) {
    names = names.map((z) => moment.tz(z).zoneAbbr());
  }
  return [...new Set(names)];
}

export function getStandardFormat(format) {
  const formats = {
    iso: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    date: 'DD MMM YYYY',
    time: 'HH:mm',
    dateTime: 'DD MMM YYYY HH:mm',
    dateTimeWithSeconds: 'DD MMM YYYY HH:mm:ss',
    dateTimeWithMilliSeconds: 'DD MMM YYYY HH:mm:ss.SSS',
  };
  return formats[format];
}

// Hook to allow components to access the renderTime function.
export function useRenderTime() {
  const { user } = useSession();
  return {
    renderTime: (timeNoOffset, format, timeZoneOverride) =>
      renderTime(timeNoOffset, format, timeZoneOverride, user),
  };
}

// Render time in the given format.
export function renderTime(timeNoOffset, format, timezoneOverride, user) {
  // Accepts standard formats or custom formats.
  format = getStandardFormat(format) || format;
  // Defaults to users Preferred Timezone, which defaults to 'America/New_York'.
  const timezone = timezoneOverride || user?.timezone || 'America/New_York';
  // TimeNoOffset must be a zero-offset time string (ends with 'Z') or a UTC time string.
  return moment(timeNoOffset).tz(timezone).format(format);
}

// Given 2 date strings or Date objects, return the difference in milliseconds.
export function compareTimes(a, b) {
  return moment(b) - moment(a);
}

// Given a date string or Date object, return the date in ISO format. Particularly useful for Dynamo.
export function getISODate(timestamp, timezone = 'GMT') {
  return moment.tz(timestamp, timezone).format('YYYY-MM-DD');
}

// Given a date string or Date object, return the date and time in ISO format. Particularly useful for Dynamo.
export function getISODateTime(timestamp, timezone = 'GMT') {
  return moment.tz(timestamp, timezone).format('YYYY-MM-DDTHH:mm:ss');
}

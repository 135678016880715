import itemBaseStyle from '@/components/LayoutTools/Sidebar/itemBaseStyle';
import { filterItems } from '@/components/LayoutTools/Sidebar/permissionHelpers';
import useSession from '@/utils/hooks/useSession';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

const activeSubgroupStyle = {
  bgcolor: 'primary.alpha.lightest',
  color: 'primary.main',
};

const subItemBaseStyle = {
  ...itemBaseStyle,
  '&:focus-visible': {
    backgroundColor: 'primary.alpha.lightest',
  },
  '&:hover': {
    backgroundColor: 'primary.alpha.lightest',
  },
};

const subItemActiveStyle = {
  bgcolor: 'primary.alpha.lightest',
  boxShadow: 'inset 8px 0',
  boxShadowColor: 'primary.main',
  color: 'primary.main',
};

function ModuleGroup({
  label,
  icon,
  items,
  active,
  sidebarWidth,
  toggleResize,
}) {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const router = useRouter();
  const { user, org } = useSession();

  const toggleSubmenu = () => {
    if (sidebarWidth < 80) {
      toggleResize();
    }
    setSubMenuOpen(!subMenuOpen);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      toggleSubmenu();
    }
  };
  const filteredItems = filterItems(items, user, org);

  return (
    <>
      <Box
        sx={{
          ...itemBaseStyle,
          ...(active && activeSubgroupStyle),
        }}
        onClick={toggleSubmenu}
        onKeyDown={handleKeypress}
        tabIndex='0'
        role='button'
      >
        {!!icon && <Box>{icon}</Box>}
        <Box sx={{ marginLeft: '16px' }}>{label}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <ExpandMoreIcon
            sx={{
              transform: subMenuOpen ? 'rotate(-180deg)' : 'rotate(0)',
              transitionProperty: 'transform',
            }}
          />
        </Box>
      </Box>
      <Collapse in={subMenuOpen && sidebarWidth > 80}>
        {filteredItems?.map((subItem) => {
          const subItemActive = router.asPath.includes(subItem.url);
          if (subItem.url === 'disabled') {
            return (
              <Box
                key={subItem.label + subItem.url}
                sx={{
                  ...subItemBaseStyle,
                  cursor: 'not-allowed',
                }}
              >
                <Box sx={{ marginLeft: '16px' }}>{subItem.label}</Box>
              </Box>
            );
          }
          return (
            <Link
              key={subItem.label + subItem.url}
              href={subItem.url}
              legacyBehavior
            >
              <Box
                sx={{
                  ...subItemBaseStyle,
                  ...(subItemActive && subItemActiveStyle),
                }}
              >
                <Box sx={{ marginLeft: '16px' }}>{subItem.label}</Box>
              </Box>
            </Link>
          );
        })}
      </Collapse>
    </>
  );
}

export default ModuleGroup;

import { CircularProgress } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  loadingWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function Loader(props) {
  const { classes } = useStyles();

  return (
    <div className={classes.loadingWrapper}>
      <CircularProgress {...props} style={{ color: '#040059' }} />
    </div>
  );
}

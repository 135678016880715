import TooltipIcon from '@/components/LayoutTools/Sidebar/TooltipIcon';
import { Apps, Star } from '@mui/icons-material';
import React from 'react';
import AdministrationIcon from '../../../public/images/Icon-Administration.svg';
import DataInsightsIcon from '../../../public/images/Icon-Data-Insights.svg';
import HomeIcon from '../../../public/images/Icon-Home.svg';
import WorkflowIcon from '../../../public/images/Icon-Workflow.svg';

const demoNavigationItems = [
  {
    label: 'Home',
    icon: <TooltipIcon src={HomeIcon} alt='Home' />,
    url: '/hub',
  },
  {
    label: 'My Favorites',
    icon: <TooltipIcon Icon={Star} alt='My Favorites' />,
    items: [
      {
        label: 'Travel Performance',
        url: '/data-insights/travel-performance',
      },
      {
        label: 'Queue Monitor',
        url: '/workflow/queue-monitor',
      },
    ],
  },
  {
    label: 'Apps',
    icon: <TooltipIcon Icon={Apps} alt='Apps' />,
    items: [
      {
        label: 'Travel Authorizations',
        url: '/travel-authorization/active-requests',
      },
      {
        label: 'TicketTRAK™',
        url: 'disabled',
      },
      {
        label: 'Schedule Change Manager',
        url: 'disabled',
      },
    ],
  },
  {
    label: 'Data Insights',
    icon: <TooltipIcon src={DataInsightsIcon} alt='Data Insights' />,
    items: [
      {
        label: 'Travel Performance',
        url: '/data-insights/travel-performance',
      },
      {
        label: 'Program Management',
        url: '/data-insights/program-management',
      },
      {
        label: 'My Reports',
        url: '/data-insights/my-reports',
      },
      {
        label: 'Event Driven Actions',
        url: '/EDA',
      },
    ],
  },
  {
    label: 'iQCX Workflow',
    icon: <TooltipIcon src={WorkflowIcon} alt='iQCX Workflow' />,
    items: [
      {
        label: 'Queue Monitor',
        url: '/workflow/queue-monitor',
      },
      {
        label: 'Schedules',
        url: '/workflow/schedules',
      },
      {
        label: 'Messaging',
        url: 'disabled',
      },
      {
        label: 'Ticketing',
        url: 'disabled',
      },
      {
        label: 'FareChecker',
        url: 'disabled',
      },
      {
        label: 'SeatChecker',
        url: 'disabled',
      },
      {
        label: 'Tables',
        url: '/workflow/tables',
      },
      {
        label: 'Table Connector',
        url: '/workflow/table-connector',
      },
      {
        label: 'ResRule Libraries',
        url: 'disabled',
      },
      {
        label: 'ResRules Composer',
        url: 'disabled',
      },
    ],
  },
  {
    label: 'Administration',
    icon: <TooltipIcon src={AdministrationIcon} alt='Administration' />,
    items: [
      {
        label: 'Users',
        url: '/user',
      },
      {
        label: 'Site Configuration',
        url: '/site-configuration',
      },
      {
        label: 'Activity Log',
        url: '/activities',
      },
    ],
  },
];

export default demoNavigationItems;

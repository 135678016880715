import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React from 'react';

export default function AutoComplete({
  placeholder,
  label,
  options,
  groupBy,
  onChange,
  loading,
  width,
  value,
  isOptionEqualToValue,
  helperText,
  getOptionLabel,
  getOptionKey,
  renderOption,
  multiple,
  filterSelectedOptions = true,
  filterOptions,
  ...props
}) {
  return (
    <Autocomplete
      key={options}
      value={value}
      options={options}
      groupBy={(option) => option[groupBy]}
      onChange={onChange}
      filterSelectedOptions={filterSelectedOptions}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      noOptionsText='No matches for your search'
      sx={{ width: width }}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      getOptionKey={getOptionKey}
      renderOption={renderOption}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          sx={{ bgcolor: 'white.main', ...props.sx }}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color='inherit' size={20} />}{' '}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...props}
        />
      )}
    />
  );
}

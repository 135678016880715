// auth
import generatePassword from './auth/generatePassword';
import getServerSession from './auth/getServerSession';
import hasPermissions from './auth/hasPermissions';
import refreshToken from './auth/refreshToken';

// aws
import getSignificantDates from './aws/dynamo/getSignificantDates';
import getOrganization from './userConfiguration/getOrganization';
import getUser from './userConfiguration/getUser';

// hooks
import useIsMobileDevice from './hooks/useIsMobileDevice';
import useLog from './hooks/useLog';
import usePrevious from './hooks/usePrevious';
import useUnsavedChanges from './hooks/useUnsavedChanges';

//hubPermissions
import {
  arbiterConfig,
  validateAndFixPermissionsConfig,
} from './hubPermissions/hubPermissionsConfig';
import {
  getOrg,
  getOrganizationFromUserEmail,
  updateOrg,
} from './hubPermissions/orgs';
import { isElevated, updateUser } from './hubPermissions/users';

//helpers
import { toArray } from './helpers';

// utils root
import { generateColor, shadeColor } from './colors';
// constants file should be imported from directly
import createEmotionCache from './createEmotionCache';
import downloadFilesFromS3 from './downloadFileFromS3';
import { getSnoozeEnd, snoozeNonExistentOrExpired } from './edas';
import getBrowserInfo from './getBrowserInfo';
import Logger from './logging';
import { commandCall } from './request';
import { SnackBarContext, useSnackBarContext } from './snackbar';
import uploadFileToS3 from './uploadFileToS3';

module.exports = {
  isElevated,
  updateUser,
  getOrg,
  updateOrg,
  getOrganizationFromUserEmail,
  arbiterConfig,
  validateAndFixPermissionsConfig,
  generatePassword,
  getServerSession,
  refreshToken,
  getOrganization,
  getSignificantDates,
  getUser,
  createEmotionCache,
  downloadFilesFromS3,
  uploadFileToS3,
  generateColor,
  shadeColor,
  getBrowserInfo,
  SnackBarContext,
  useSnackBarContext,
  usePrevious,
  useUnsavedChanges,
  useIsMobileDevice,
  useLog,
  hasPermissions,
  Logger,
  toArray,
  commandCall,
  getSnoozeEnd,
  snoozeNonExistentOrExpired,
};

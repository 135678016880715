import EulaAgreement from '@/components/LayoutTools/Eula/EulaAgreement';
import ErrorBoundary from '@/components/Reusable/ErrorHandling/ErrorBoundary';
import ErrorComponent from '@/components/Reusable/ErrorHandling/ErrorComponent';
import SnackBarAlert from '@/components/Reusable/GeneralUse/SnackBarAlert';
import { SessionProvider } from '@/utils/auth/SessionContext';
import { amplifyConfig } from '@/utils/aws/configs';
import { HelpPanelContext } from '@/utils/helpPanelContext';
import { SnackBarContext } from '@/utils/snackbar';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license';
import { Amplify } from 'aws-amplify';
import axios from 'axios';
import { Lato, Montserrat } from 'next/font/google';
import { useRouter } from 'next/router';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Layout from '../components/LayoutTools/Layout';
import '../components/PageSpecific/metrics/D3/index.css';
import '../components/PageSpecific/travel-authorization/logs/index.css';
import createHubTheme from '../styles/createHubTheme';
import '../styles/fonts.css';
import createEmotionCache from '../utils/createEmotionCache';

Amplify.configure({ ...amplifyConfig, ssr: true });
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY);

const queryClient = new QueryClient();

const clientSideEmotionCache = createEmotionCache();

const lato = Lato({
  subsets: ['latin'],
  weight: ['400', '700'],
});
const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['400', '700'],
});
const fontClassnames = `${lato.className} ${montserrat.className}`;

function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { session, ...pageProps },
}) {
  // Configure routing.
  const router = useRouter();
  useEffect(() => {
    nProgress.configure({
      parent: '#nprogress-container',
    });
    router.events.on('routeChangeStart', nProgress.start);
    router.events.on('routeChangeComplete', nProgress.done);
    router.events.on('routeChangeError', nProgress.done);
    return () => {
      router.events.off('routeChangeStart');
      router.events.off('routeChangeComplete');
      router.events.off('routeChangeError');
    };
  }, []);

  // set axios header from jwt in session
  axios.defaults.headers.common.Authorization = `Bearer ${session?.token}`;

  if (session?.isImpersonating) {
    axios.defaults.headers.common.impuser = session.user.email;
    axios.defaults.headers.common.imporg = session.org.name;
  }

  // Create theme.
  const themeColor = session?.org?.themeColor || '#040059';
  const theme = createHubTheme(themeColor);

  // Handle snackbar alerts globally.
  const [openSnack, setOpenSnack] = useState(false);
  const [alert, setAlert] = useState(undefined);
  const [snackPack, setSnackPack] = useState([]);
  const updateSnack = (alertObj) => {
    setSnackPack((prev) => [
      ...prev,
      { ...alertObj, key: new Date().getTime() },
    ]);
  };

  const helpPanelRef = React.createRef();

  // Prevent rendering of layout on 404 page. 404 pages are required to be static, and
  // thus they cannot use getServerSideProps. See
  // https://nextjs.org/docs/messages/404-get-initial-props for more info.
  if (router.route === '/404') {
    return <Component {...pageProps} />;
  }

  return (
    <CacheProvider value={emotionCache}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SessionProvider session={session}>
            <QueryClientProvider client={queryClient}>
              <SnackBarContext.Provider value={{ updateSnack }}>
                <HelpPanelContext.Provider value={{ helpPanelRef }}>
                  <Layout
                    helpPanelRef={helpPanelRef}
                    fontClassnames={fontClassnames}
                  >
                    <ErrorBoundary type='page'>
                      {pageProps.error ? (
                        <ErrorComponent message={pageProps.error} />
                      ) : (
                        <Component {...pageProps}></Component>
                      )}
                      <EulaAgreement />
                      <SnackBarAlert
                        openSnack={openSnack}
                        setOpenSnack={setOpenSnack}
                        alert={alert}
                        setAlert={setAlert}
                        snackPack={snackPack}
                        setSnackPack={setSnackPack}
                      />
                    </ErrorBoundary>
                  </Layout>
                </HelpPanelContext.Provider>
              </SnackBarContext.Provider>
            </QueryClientProvider>
          </SessionProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  );
}

export default App;

import GeneralUseDialog from '@/components/Reusable/GeneralUse/Dialogs/GeneralUseDialog';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';

export const termsOfUseTitle = 'TERMS OF USE';

export const TermsOfUseText = () => {
  return (
    <Box>
      <Typography variant='subtitle1' gutterBottom>
        Version: June 2024
      </Typography>

      <Typography variant='h2' gutterBottom>
        Introduction
      </Typography>
      <Typography paragraph>
        Welcome to the Cornerstone Information Systems, Inc ("Cornerstone,"
        "CIS," "We," "Us," "Our") and its subsidiaries Web Site (this "Web
        Site"). These Terms of Use (the "Terms") explain a contractual agreement
        between you ("User," "You," "Your") and Cornerstone regarding Your use
        of this Web Site. By accessing or using this Web Site, You agree to be
        bound by these Terms, our{' '}
        <Link href='/privacy-policy'>Privacy Policy</Link>, and any additional
        terms and conditions that may apply to specific sections of the Web Site
        or Services. PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR
        USING OUR WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS OF USE AND ALL
        TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS, DO
        NOT USE OUR WEBSITE.
      </Typography>

      <Typography variant='h2' gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography paragraph>
        By accessing or using the Cornerstone Information Systems website (the
        "Site") or any services provided through the Site (collectively, the
        "Services"), you agree to be bound by these Terms of Use (the "Terms"),
        our <Link href='/privacy-policy'>Privacy Policy</Link>, and any
        additional terms and conditions that may apply to specific sections of
        the Site or Services. These Terms constitute a legally binding agreement
        between you and Cornerstone Information Systems ("we," "us," or "our").
      </Typography>
      <Typography paragraph>
        We reserve the right to update, modify, or replace these Terms at any
        time without prior notice. Any changes to the Terms will be effective
        immediately upon posting on the Site. Your continued use of the Site or
        Services after any such changes constitutes your acceptance of the new
        Terms.
      </Typography>
      <Typography paragraph>
        <strong>Eligibility:</strong> By using the Site or Services, you
        represent and warrant that you are at least 18 years old and have the
        legal capacity to enter into these Terms. If you are using the Site or
        Services on behalf of an entity, you represent and warrant that you have
        the authority to bind that entity to these Terms.
      </Typography>

      <Typography variant='h2' gutterBottom>
        2. Purpose of this Web Site
      </Typography>
      <Typography paragraph>
        The goal of this Web Site is to provide You with access to or
        information about Our services, products, and related links to meet Your
        needs (the "Content"). The Content on this Web Site, including all
        Materials, are for general educational and informational purposes only
        and are not intended to be used for legal, accounting, medical, or other
        professional advice and should not be relied on as such. CIS does not
        endorse any statements or opinions made in the Materials nor determine
        the truthfulness, accuracy, reliability, currency, or completeness
        thereof. Use of the Web Site or Materials and any reliance on the
        Materials will be at your own risk.
      </Typography>

      <Typography variant='h2' gutterBottom>
        3. User Responsibilities and Prohibited Activities
      </Typography>
      <Typography paragraph>
        You warrant and represent that You will not use this Web Site for any
        purpose that is unlawful or prohibited by these Terms, including without
        limitation:
      </Typography>
      <Typography paragraph>
        a. Reverse engineering, decompiling, disassembling, or otherwise
        attempting to discover the source code, object code, or underlying
        structure, ideas, know-how, or algorithms relevant to the Services.
      </Typography>
      <Typography paragraph>
        b. Copying, modifying, translating, or creating derivative works based
        on the Services.
      </Typography>
      <Typography paragraph>
        c. Renting, leasing, lending, selling, sublicensing, assigning,
        distributing, publishing, transferring, or otherwise making available
        any Services to any person.
      </Typography>
      <Typography paragraph>
        d. Accessing or using the Services in any manner or for any purpose that
        infringes, misappropriates, or otherwise violates any intellectual
        property right or other right of any third party, or that violates any
        applicable law.
      </Typography>
      <Typography paragraph>
        e. Accessing or using the Services for purposes of competitive analysis
        of the Services.
      </Typography>
      <Typography paragraph>
        f. Posting or transmitting any threatening, libelous, defamatory,
        obscene, scandalous, inflammatory, pornographic, or profane material.
      </Typography>

      <Typography variant='h3' gutterBottom>
        User Accounts
      </Typography>
      <Typography paragraph>
        Some parts of the Site or Services may require you to create an account.
        You agree to provide accurate, current, and complete information during
        the registration process and to update such information as necessary.
        You are responsible for maintaining the confidentiality of your account
        and password and for restricting access to your computer or device. You
        agree to accept responsibility for all activities that occur under your
        account or password.
      </Typography>
      <Typography paragraph>
        In the event that You are provided with User identification numbers or
        codes (collectively, "ID's"), confirmation numbers, and/or passwords (as
        applicable) in the use of this Web Site, You shall maintain such User
        ID's, confirmation numbers, and/or passwords in confidence and You agree
        not to distribute or disclose the same to third parties. It is Your
        responsibility to notify Us if We need to change or discontinue any of
        Your ID's, confirmation numbers, or passwords. It is also Your
        responsibility to immediately request discontinuation of an ID,
        confirmation number, or password upon Your knowledge or belief that such
        ID, confirmation number, and/or password is, or may be subject to, a
        breach of confidentiality. We may suspend or terminate Your service or
        access to this Web Site if We believe a breach of these Terms has
        occurred.
      </Typography>
      <Typography paragraph>
        You agree to provide true, accurate, current and complete Information.
        If You provide any Information that is untrue, inaccurate, not current
        or incomplete (or We have reasonable grounds to suspect that such
        Information is untrue, inaccurate, not current or incomplete), We have
        the right to suspend or terminate Your access and activity, and refuse
        any and all current or future use of this Web Site.
      </Typography>

      <Typography variant='h2' gutterBottom>
        4. Intellectual Property, License Grant, and Software License Agreement
      </Typography>
      <Typography paragraph>
        4.1 <strong>Proprietary Rights:</strong> All content on this Web Site,
        including text, photographs, images, illustrations, audio clips, and
        video clips, is protected by copyrights, trademarks, service marks,
        and/or other intellectual property rights. You may not use, reproduce,
        republish, upload, post, transmit, distribute, or exploit the content in
        any way without our prior written consent.
      </Typography>
      <Typography paragraph>
        4.2 <strong>Grant of License:</strong> CIS grants you a non-assignable
        and non-transferable limited license to access and use (display, print,
        download) the Materials and Services for your personal, non-commercial
        use. You must retain all copyright notices such as trademark notices and
        other proprietary notices that are contained in the original Materials.
        You are not permitted to copy, modify, reproduce, sell, rent, lease,
        loan, distribute, or otherwise dispose of, deal with, or use the
        Materials or Services for any public purpose or for any commercial gain.
      </Typography>
      <Typography paragraph>
        Parts of this Web Site are available only to registered users and
        subject to payment and other agreements. You agree not to attempt access
        to these parts of the Web Site without complying with all terms of such
        agreements. If you are authorized to access a part of this Web Site for
        a certain time period, then the license to use the Materials and
        Services will terminate upon the expiration of the applicable time
        period.
      </Typography>
      <Typography paragraph>
        4.3{' '}
        <strong>
          Software License Agreements Incorporated in Terms of Us:
        </strong>{' '}
        All software that is accessed from this Web Site are governed by
        software license agreements ("SLAs"), the terms and conditions of which
        SLAs supplement these Terms of Use and are incorporated in these Terms
        of Use by reference. In the event of a conflict between these Terms of
        Use and the terms and conditions of the applicable SLA, the terms and
        conditions of the applicable SLA will prevail. All software applications
        not governed by a specific software license agreement are governed by
        these Terms of Use.
      </Typography>

      <Typography variant='h2' gutterBottom>
        5. Privacy
      </Typography>
      <Typography paragraph>
        Your use of this Web Site is subject to our{' '}
        <Link href='/privacy-policy'>Privacy Policy</Link>, which is
        incorporated into these Terms by reference. Please review our Privacy
        Policy for more information on how we collect, use, and share your
        personal information.
      </Typography>

      <Typography variant='h2' gutterBottom>
        6. Violation of Rules and Regulations
      </Typography>
      <Typography paragraph>
        Cornerstone Information Systems reserves the right to seek all remedies
        available at law and in equity for violations of these Rules and
        Regulations, including the right to block access from a particular
        internet address to this Web Site.
      </Typography>

      <Typography variant='h2' gutterBottom>
        7. Use of this Web Site
      </Typography>
      <Typography paragraph>
        We control and operate this Web Site. All Content on this Web Site,
        including, but not limited to, text, photographs, images, illustrations,
        audio clips, and video clips, is protected by copyrights, trademarks,
        service marks, and/or other intellectual property rights (which are
        governed by United States and worldwide copyright laws and treaty
        provisions, privacy and publicity laws, and communication regulations
        and statutes). The Content is owned and controlled by Us or Our
        affiliates, or by third party content providers, merchants, sponsors and
        licensors (collectively the "Providers") that have licensed their
        content or the right to market their products and/or services to Us.
        Content on this Web Site or any Web Site owned, operated, licensed or
        controlled by the Providers is solely for Your personal, non-commercial
        use. You agree to abide by all additional copyright notices,
        information, or restrictions contained in any Content. You may download
        a single copy of any Content contained on this Web Site, solely for Your
        personal, non-commercial use, consistent with these Terms, provided that
        You maintain all copyright and other notices contained in such Content.
      </Typography>
      <Typography paragraph>
        You may not copy, reproduce, republish, upload, post, transmit,
        distribute, and/or exploit the Content in any way (including by e-mail
        or other electronic means) for commercial use without the prior written
        consent of Us or the Providers. You may request consent by emailing a
        request to Cornerstone Information Systems support department,{' '}
        <Link href='mailto:support@ciswired.com'>support@ciswired.com</Link>.
        Your modification of the Content, use of the Content on any other Web
        Site or networked computer environment, or use of the Content for any
        purpose other than personal, non-commercial use, without the prior
        written consent of Us or the Providers, violates the intellectual
        property rights and proprietary rights of the Content owners and is
        prohibited.
      </Typography>
      <Typography paragraph>
        You may not use on Your Web Site any registered or unregistered
        trademarks, service marks or copyrighted materials appearing on this Web
        Site, including but not limited to any logos or characters, without the
        express written consent of the owner of the mark or copyright. You may
        not frame, deep link, or otherwise incorporate into another Web Site any
        of the Content or other materials on this Web Site without Our express
        prior written consent.
      </Typography>
      <Typography paragraph>
        Violation of trademark and copyright laws may result in significant
        civil liability or criminal penalties under United States and/or
        worldwide copyright and trademark laws. You recognize that any
        reproduction or use of Content, except as authorized by these Terms, is
        considered intentional infringement.
      </Typography>

      <Typography variant='h2' gutterBottom>
        8. DISCLAIMER OF WARRANTIES
      </Typography>
      <Typography paragraph>
        ALL CONTENT ON THIS WEB SITE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES
        OF ANY KIND, EITHER EXPRESS OR IMPLIED. OTHER THAN THOSE WARRANTIES
        WHICH, UNDER THE LAWS APPLICABLE TO THESE TERMS, ARE IMPLIED BY LAW AND
        ARE INCAPABLE OF EXCLUSION, RESTRICTION, OR MODIFICATION, WE DISCLAIM
        ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
        TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
        PURPOSE.
      </Typography>

      <Typography variant='h2' gutterBottom>
        9. LIMITATION OF LIABILITY
      </Typography>
      <Typography paragraph>
        IN NO EVENT SHALL WE NOR OUR AFFILIATED OR RELATED ENTITIES, NOR ANY OF
        OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, NOR ANY PERSON
        OR ENTITY INVOLVED IN THE CREATION, PRODUCTION, AND DISTRIBUTION OF THIS
        WEB SITE, BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL,
        SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO
        YOUR USE OF THIS WEB SITE, REGARDLESS OF THE FORM OF ACTION.
      </Typography>

      <Typography variant='h2' gutterBottom>
        10. Indemnification
      </Typography>
      <Typography paragraph>
        You agree to indemnify, defend, and hold Us and the Providers, Our and
        their officers, directors, employees, affiliates, agents, licensors, and
        suppliers harmless from and against any and all claims, demands,
        actions, costs, liabilities, losses, and damages of any kind (including
        attorneys' fees) resulting from Your use of this Web Site, Your breach
        of any provision of these Terms, and/or any negligent acts, omissions,
        or intentional wrongdoing by You.
      </Typography>

      <Typography variant='h2' gutterBottom>
        11. Governing Law and Dispute Resolution
      </Typography>
      <Typography paragraph>
        We operate this Web Site (excluding linked Sites) from Our offices
        within the State of Indiana. It can be accessed from all 50 states, as
        well as from other countries around the world. As each of these places
        has laws that may differ from those of Indiana, by accessing this Web
        Site, You agree that these Terms and Your use of the Web Site shall be
        governed in all respect by the internal substantive laws of the State of
        Indiana, without regard to conflict of laws provisions and shall not be
        governed by the United Nations Convention on the International Sale of
        Goods. You further submit to exclusive jurisdiction and venue in the
        state and federal courts located in the State of Indiana for all
        disputes, cases and controversies regarding this Web Site, Your use of
        this Web Site, and Your relationship with Us. We make no representation
        that materials on this Web Site are appropriate or available for use in
        other locations, and accessing them from territories where the Content
        is illegal is prohibited.
      </Typography>
      <Typography paragraph>
        Those who choose to access this Web Site from other locations do so at
        their own risk and are responsible for compliance with local laws,
        including laws regarding the transmission of technical data exported
        from the United States or the country in which You reside.
      </Typography>
      <Typography paragraph>
        11.1 <strong>Governing Law:</strong> These Terms shall be governed by
        and construed in accordance with the laws of the State of Indiana
        without regard to its conflict of laws principles.
      </Typography>
      <Typography paragraph>
        11.2 <strong>Arbitration:</strong> ANY CONTROVERSY OR CLAIM ARISING OUT
        OF OR RELATING TO THIS CONTRACT, OR THE BREACH THEREOF, SHALL BE SETTLED
        BY ARBITRATION ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION IN
        ACCORDANCE WITH ITS COMMERCIAL ARBITRATION RULES APPLYING INDIANA LAW,
        AND JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR(S) MAY BE ENTERED
        IN ANY COURT HAVING JURISDICTION THEREOF.
      </Typography>

      <Typography variant='h2' gutterBottom>
        12. Feedback and Submissions
      </Typography>
      <Typography paragraph>
        If you provide us with any feedback, suggestions, or other submissions
        related to the Web Site or Services, you grant Cornerstone Information
        Systems a non-exclusive, royalty-free, perpetual, irrevocable, and fully
        sublicensable right to use, reproduce, modify, adapt, publish,
        translate, create derivative works from, distribute, and display such
        feedback or submissions throughout the world in any media.
      </Typography>

      <Typography variant='h2' gutterBottom>
        13. Termination
      </Typography>
      <Typography paragraph>
        We may terminate or suspend your access to the Web Site and Services
        immediately, without prior notice or liability, for any reason
        whatsoever, including without limitation if you breach these Terms.
      </Typography>

      <Typography variant='h2' gutterBottom>
        14. Other General Provisions
      </Typography>
      <Typography paragraph>
        14.1 <strong>Entire Agreement:</strong> These Terms, our Privacy Policy,
        and any additional terms and conditions that may apply to specific
        sections of the Web Site or Services constitute the sole and entire
        agreement between you and Cornerstone Information Systems or our
        subsidiaries or affiliates regarding the Web Site and Services and
        supersede all prior and contemporaneous understandings, agreements,
        representations, and warranties, both written and oral, regarding the
        Web Site and Services.
      </Typography>
      <Typography paragraph>
        14.2 <strong>Severability:</strong> If any provision of these Terms is
        found to be unenforceable or invalid, that provision will be limited or
        eliminated to the minimum extent necessary so that these Terms will
        otherwise remain in full force and effect and enforceable.
      </Typography>
      <Typography paragraph>
        14.3 <strong>Waiver:</strong> No waiver of these Terms by Us shall be
        deemed a further or continuing waiver of such term or condition or any
        other term or condition, and any failure by Us to assert a right or
        provision under these Terms shall not constitute a waiver of such right
        or provision.
      </Typography>
      <Typography paragraph>
        14.4 <strong>Assignment:</strong> These Terms are not assignable,
        transferable, or sublicensable by You except with Our prior written
        consent. We may transfer and assign any of Our rights and obligations
        under these Terms without Your consent.
      </Typography>
      <Typography paragraph>
        14.5 <strong>Notices:</strong> All notices under these Terms will be in
        writing and will be deemed to have been duly given when received, if
        personally delivered; when receipt is electronically confirmed, if
        transmitted by facsimile or e-mail; the day after it is sent, if sent
        for next day delivery by recognized overnight delivery service; and upon
        receipt, if sent by certified or registered mail, return receipt
        requested.
      </Typography>
      <Typography paragraph>
        These Terms are for the benefit of Us and the Providers, Our and their
        officers, directors, employees, affiliates, agents, licensors, and
        suppliers, and each of them shall have the right to assert and enforce
        these Terms directly or on their behalf.
      </Typography>

      <Typography variant='h2' gutterBottom>
        Contact Information
      </Typography>
      <Typography paragraph>
        For any questions about these Terms or the Web Site, please contact us
        at:
      </Typography>
      <Typography paragraph>
        Cornerstone Information Systems, Inc.
        <br />
        Email:{' '}
        <Link href='mailto:support@ciswired.com'>support@ciswired.com</Link>
      </Typography>
    </Box>
  );
};

function TermsOfUseDialog({ open, setOpen }) {
  return (
    <GeneralUseDialog
      open={open}
      setOpen={setOpen}
      contentTitle={termsOfUseTitle}
      contentText={<TermsOfUseText />}
    />
  );
}

export default TermsOfUseDialog;

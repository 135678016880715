import { useTheme } from '@emotion/react';
import { Box, Container, useMediaQuery } from '@mui/material';
import { Analytics } from '@vercel/analytics/react';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Header, HEADER_HEIGHT } from '../Header';
import Sidebar from '../Sidebar/Sidebar';

function Layout({ children, fontClassnames }) {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setMobileMenuOpen(open);
  };

  const noLayoutUrls = [
    '/login',
    '/_error',
    '/confirm-password-reset',
    '/finish-registration',
    '/forgot-password',
    '/privacy-policy',
    '/terms-of-use',
    '/sso',
  ];
  if (noLayoutUrls.includes(router.pathname)) {
    return (
      <Box
        component='main'
        sx={{ width: '100%' }}
        flex
        className={fontClassnames}
        id='nprogress-container'
      >
        {children}
      </Box>
    );
  }

  return (
    <>
      <Header isMobile={isMobile} toggleMobileMenu={toggleDrawer} />
      <Box
        sx={{
          display: 'flex',
          height: `calc(100vh - ${HEADER_HEIGHT}px)`,
          bgcolor: 'white',
        }}
      >
        <Sidebar toggleDrawer={toggleDrawer} mobileMenuOpen={mobileMenuOpen} />
        <Container
          id='nprogress-container'
          maxWidth={false}
          sx={{ overflow: 'auto' }}
        >
          <Box
            component='main'
            sx={{ width: '100%', height: '100%', pl: 1 }}
            flex
            className={fontClassnames}
          >
            {children}
            <Analytics />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Layout;

import CornerstoneLogo from '@/assets/logos/CornerstoneLogo';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';

const ContactUsModal = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='md'>
      <DialogTitle>
        <CornerstoneLogo />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>304 W. Kirkwood Ave.</Typography>
          <Typography>Suite 101</Typography>
          <Typography>Bloomington, IN 47404 USA</Typography>
          <Typography sx={{ mt: 2 }}>Toll Free: +1 (800) 276-8255</Typography>
          <Typography>USA/North America: +1 (812) 330-4361</Typography>
          <Typography sx={{ mt: 2 }}>
            Sales: <a href='mailto:ask@ciswired.com'>ask@ciswired.com</a>
          </Typography>
          <Typography>
            Support:{' '}
            <a href='mailto:support@ciswired.com'>support@ciswired.com</a>
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsModal;

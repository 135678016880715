import { Button } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  title: {
    fontSize: '3em',
    fontWeight: 600,
  },
  code: {
    fontSize: '5em',
    color: theme.palette.primary.main,
    margin: '-1rem 0',
    fontWeight: 600,
  },
  message: {
    fontSize: '2em',
  },
}));

const ErrorScreen = (props) => {
  const { title, code, message, fontSize, goBack, refresh } = props;
  const router = useRouter();
  const { classes } = useStyles();

  const handleRefresh = () => {
    !!refresh && refresh();
    goBack ? router.back() : router.push('/hub');
  };

  return (
    <div className={classes.main} style={{ fontSize: fontSize || '1rem' }}>
      {code !== false && <div className={classes.code}>{code || '500'}</div>}
      {title !== false && (
        <div className={classes.title}>{title || 'Oops!'}</div>
      )}
      <div className={classes.message}>
        {message || 'An unexpected error has occurred'}
      </div>
      <br />
      <br />
      {goBack !== false && (
        <Button
          variant='contained'
          color='secondary'
          size='large'
          onClick={handleRefresh}
        >
          Go back
        </Button>
      )}
    </div>
  );
};

export default ErrorScreen;

import ModuleGroup from '@/components/LayoutTools/Sidebar/ModuleGroup';
import SingleLinkModule from '@/components/LayoutTools/Sidebar/SingleLinkModule';
import { useRouter } from 'next/router';
import React from 'react';

export default function SidebarItem({
  label,
  icon,
  url,
  items,
  sidebarWidth,
  toggleResize,
}) {
  const router = useRouter();

  if (url) {
    const active = router.asPath === url;
    return (
      <SingleLinkModule
        active={active}
        label={label}
        icon={icon}
        url={url}
        sidebarWidth={sidebarWidth}
        toggleResize={toggleResize}
      />
    );
  } else if (items?.length) {
    const active = items.map((i) => i.url).includes(router.asPath);
    return (
      <ModuleGroup
        label={label}
        icon={icon}
        items={items}
        active={active}
        router={router}
        sidebarWidth={sidebarWidth}
        toggleResize={toggleResize}
      />
    );
  }
  return null;
}
